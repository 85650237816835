import { ref } from '@vue/composition-api'

import { pushPopup } from '@/utils/pushPopup'
import { popupsArr } from './arrs'
import webhook from '@/webhook'

const comics = [
  { url: require('@/assets/lesson1/scene5_1.svg') },
]

export default {
  name: 'step4',
  setup(props, ctx) {
    const { $router } = ctx.root
    const active = ref(true)
    const popups = ref([])
    const wrapperMode = ref(false)

    const init = () => {
      pushPopup(popupsArr, popups.value)
    }

    init()

    const nextStep = () => {
      webhook.reqMarkFirstTask()
      $router.push('/education2/lesson2')
    }

    return {
      active,
      popups,
      wrapperMode,
      comics,
      nextStep,
    }
  },
}
